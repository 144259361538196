import pariu from "../assets/portfolio/pariu_cu_viata.jpg";
import hope from "../assets/portfolio/hope_mountain.png";
import naufrage from "../assets/portfolio/Naufrage.png";
import memnon from "../assets/portfolio/Mem-Non.png";
import sector7 from "../assets/portfolio/Sector7.png";
import anonim from "../assets/portfolio/anonim_celebru.jpg";
import paul from "../assets/portfolio/lumea_lui_paul.png";
import const2 from "../assets/portfolio/constangeles2.jpg";
import tripolar from "../assets/portfolio/tripolar.jpg";
import roata5 from "../assets/portfolio/a_cincea_roata.jpg";
import plecat from "../assets/portfolio/plecat_de_acasa.jpg";
import okapiTati from "../assets/portfolio/okapi_tati.png";
import destin from "../assets/portfolio/dl_destin.jpg";
import drumul from "../assets/portfolio/grasu_drumul_succes.jpg";
import fangai from "../assets/portfolio/FanGai.jpg";
import emag from "../assets/portfolio/Emag.png";
import petrila from "../assets/portfolio/planeta_petrila.jpg";
import zip from "../assets/portfolio/ZipUp.png";
import micro from "../assets/portfolio/Microcosmos.png";
import personal from "../assets/portfolio/nu_o_lua_personal.jpg";
import povestea from "../assets/portfolio/mihaita_si_istvan.png";
import diagnostics from "../assets/portfolio/top_diagnostics.png";
import rainbow from "../assets/portfolio/rainbow_unseen.png";
import sound from "../assets/portfolio/SoundThimble.png";
import raniseptol from "../assets/portfolio/Raniseptol.png";
import salviasept from "../assets/portfolio/Salviasept.png";
import podcast from "../assets/portfolio/Podcast_UNATC.png";
import wounds from "../assets/portfolio/incompat_wounds.png";
import digi from "../assets/portfolio/Digi_4k.png";
import fantana from "../assets/portfolio/la_fantana.png";

import { PortfolioCardProps } from "./PorfolioCard";

export const portfolioCards: PortfolioCardProps[] = [
  {
    title: "Tranda – Tripolar",
    description: "producer / music / arrangement / mixing / mastering",
    imageSrc: tripolar,
    link: "https://youtu.be/kxZF6GO3Fpk",
  },
  {
    title: "ZipUp",
    description: "music / mixing",
    imageSrc: zip,
    link: "https://youtu.be/C9sQ577d5HI",
  },
  {
    title: "Povestea lui Mihaita si Ivan",
    description: "sound design / music / mixing",
    imageSrc: povestea,
    link: "https://youtu.be/z4_5kJxRJYQ",
  },
  {
    title: "Tranda – Constangeles 2 (album)",
    description:
      "recording engineer / arrangement / music / mixing (shared credits)",
    imageSrc: const2,
    link: "https://youtu.be/omBlK57y4wQ",
  },
  {
    title: "Digi 4k",
    description: "music / sound design / mixing",
    imageSrc: digi,
    link: "https://youtu.be/bu5bOstZp0A",
  },
  {
    title: "Grasu XXL cu Guess Who – Dl Destin",
    description: "sound design",
    imageSrc: destin,
    link: "https://youtu.be/-XhyJaBIahU",
  },
  {
    title: "Sector 7",
    description: "Music",
    imageSrc: sector7,
    link: "https://youtu.be/6YtnZqHA_Rw",
  },
  {
    title: "SoundThimble",
    description: "sound design / interaction design",
    imageSrc: sound,
    link: "https://youtu.be/5y_ijrvs_y4",
  },
  {
    title: "Guess Who – Un Anonim Celebru (album)",
    description: "recording engineer / arrangement / music (shared credits)",
    imageSrc: anonim,
    link: "https://youtu.be/Pzmpxk4iR3U",
  },
  {
    title: "Hope Mountain",
    description: "sound design / music / mixing",
    imageSrc: hope,
    link: "https://youtu.be/PA4wM7eueG0",
  },
  {
    title: "Zdrovit Raniseptol",
    description: "sound design / music / mixing",
    imageSrc: raniseptol,
    link: "https://youtu.be/okUZ8jGaOZM",
  },
  {
    title: "Top Diagnostics",
    description: "sound design / music / mixing",
    imageSrc: diagnostics,
    link: "https://youtu.be/LZp_vBG9NLI",
  },
  {
    title: "Naufrage (32-channel electroacoustic composition)",
    description: "recording / sound design / composition / spatialization",
    imageSrc: naufrage,
    link: "https://youtu.be/lQRgWtEL3JM",
  },
  {
    title: "Microcosmos (light-sound installation)",
    description: "sound design / music / mixing",
    imageSrc: micro,
    link: "https://youtu.be/UJyQS3v2lps",
  },
  {
    title: "Nu O Lua Personal (interactive performance-installation)",
    description: "sound design / interaction design",
    imageSrc: personal,
    link: "https://vimeo.com/359541803",
  },
  {
    title: "Nane – Plecat de acasa (album)",
    description: "recording engineer / arrangement / music (shared credits)",
    imageSrc: plecat,
    link: "https://youtu.be/TlGrVHGj9x0",
  },
  {
    title: "The Rainbow Unseen",
    description: "sound design / composition",
    imageSrc: rainbow,
    link: "https://youtu.be/DpehRPRqZdo",
  },
  {
    title: "Okapi Tati (mixtape)",
    description: "recording engineer / arrangement",
    imageSrc: okapiTati,
    link: "https://youtu.be/OuhFUfagFU8",
  },
  {
    title: "FANGAI (16-channel interactive installation)",
    description: "sound design / interaction design",
    imageSrc: fangai,
    link: "https://youtu.be/3LFaDtg3AL8",
  },
  {
    title: "EMAG Marketplace",
    description: "sound design / mixing",
    imageSrc: emag,
    link: "https://youtu.be/PZ6uUfTeppk",
  },
  {
    title: "Podcast UNATC",
    description: "sound design / mixing",
    imageSrc: podcast,
    link: "https://youtu.be/WNBNFFIqZms",
  },
  {
    title: "Mem-Non",
    description: "audio programming",
    imageSrc: memnon,
    link: "http://www.martinbalint.com/memnon",
  },
  {
    title: "Incompatible Wounds",
    description: "music / sound design / mixing",
    imageSrc: wounds,
    link: "https://youtu.be/Q7hk7isuqw8",
  },
  {
    title: "Spike – Lumea lui Paul (album)",
    description: "recording engineer / arrangement / music (shared credits)",
    imageSrc: paul,
    link: "https://youtu.be/OdUACZFpiu0",
  },
  {
    title: "Maximilian – A 5a roata la caruta (album)",
    description: "recording engineer",
    imageSrc: roata5,
    link: "https://youtu.be/MoItHyqL_-U",
  },
  {
    title: "Planeta Petrila",
    description: "5.1 music mixing",
    imageSrc: petrila,
    link: "https://www.imdb.com/title/tt6432808/",
  },
  {
    title: "Pariu cu viata",
    description: "sound design for international adr / mixing (shared credits)",
    imageSrc: pariu,
    link: "https://protvplus.ro/emisiuni/pariu-cu-viata",
  },
  {
    title: "La Fantana",
    description: "sound design / mixing",
    imageSrc: fantana,
    link: "https://youtu.be/710rbDBzW18",
  },
  {
    title: "Grasu XXL – Drumul spre succes (album)",
    description: "arrangement (shared credits)",
    imageSrc: drumul,
    link: "https://youtu.be/4IMAYQoY-Es",
  },
  {
    title: "Zdrovit Salvia Sept",
    description: "sound design / music / mixing",
    imageSrc: salviasept,
    link: "https://youtu.be/S5rtIkxTQzo",
  },
];
