import styled from "styled-components";

export const PortfolioPageContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 1140px;
  justify-content: center;
  margin: auto;

  a {
    height: 100%;
  }
`;
