import acum from "../assets/partners/Acum.png";
import developingArt from "../assets/partners/Developing_Art.png";
import eatUsAlive from "../assets/partners/EatUsAlive.png";
import far from "../assets/partners/FAR.png";
import imago from "../assets/partners/Imago.png";
import insonora from "../assets/partners/Insonora.png";
import pmb from "../assets/partners/PMB_Arcub.png";
import radar from "../assets/partners/Radar.png";
import unatc from "../assets/partners/UNATC_CINETic.png";
import unicredit from "../assets/partners/Unicredit.png";
import styled from "styled-components";

const ClientLogosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  img {
    margin: ${(p) => p.theme.spacing(2)};
  }
`;

export const Partners = () => (
  <ClientLogosContainer>
    <img height="50px" src={pmb} alt="" />
    <img height="25px" src={unicredit} alt="" />
    <img height="25px" src={radar} alt="" />
    <img height="25px" src={far} alt="" />
    <img height="25px" src={unatc} alt="" />
    <img height="40px" src={insonora} alt="" />
    <img height="25px" src={imago} alt="" />
    <img height="50px" src={developingArt} alt="" />
    <img height="20px" src={eatUsAlive} alt="" />
    <img height="40px" src={acum} alt="" />
  </ClientLogosContainer>
);
