import styled from "styled-components";
import { Typography } from "@mui/material";

const ClientLogosContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  img {
    margin: ${(p) => p.theme.spacing(2)};
  }
`;

const ClientLogosBox = styled.div`
  margin-top: ${(p) => p.theme.spacing(4)};
  text-align: center;
`;

export const LogosSection: React.FC<{
  logoSrcs: string[];
  title: string;
  height?: string;
  width?: string;
}> = ({ logoSrcs, title, height, width }) => {
  return (
    <ClientLogosBox>
      <Typography fontSize={"large"}>{title}</Typography>
      <ClientLogosContainer>
        {logoSrcs.map((l) => (
          <img width={width} height={height} src={l} alt="" key={l} />
        ))}
      </ClientLogosContainer>
    </ClientLogosBox>
  );
};
