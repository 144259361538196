import { Route, Routes } from "react-router-dom";
import styled, { ThemeProvider } from "styled-components";
import { AboutPage } from "./aboutPage/AboutPage";
import { Navigation } from "./navigation/Navigation";
import { PortfolioPage } from "./portfolioPage/PortfolioPage";
import { SonicAvatarsPage } from "./sonicAvatarsPage/SonicAvatarsPage";
import { theme } from "./theme/theme";
import "@fontsource/source-code-pro";

const AppContainer = styled.div`
  max-width: 1140px;
  margin: auto;
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AppContainer>
        <Navigation />
        <Routes>
          <Route path="/" element={<SonicAvatarsPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/portfolio" element={<PortfolioPage />} />
        </Routes>
      </AppContainer>
    </ThemeProvider>
  );
}

export default App;
