export const ByStefan = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="72.875"
    viewBox="0 0 252.561 72.875"
  >
    <g
      id="Group_177"
      data-name="Group 177"
      transform="translate(-66.613 -1773.421)"
    >
      <g id="Group_176" data-name="Group 176">
        <path
          id="Path_107"
          data-name="Path 107"
          d="M78.672,1805.332c1.871,1.61,2.829,4.832,2.829,11.492v5.311c0,11.406-2.786,14.845-8.445,14.845H66.613v-63.123h6.443c5.616,0,8.445,3.047,8.445,14.54v5.311c0,6.617-.958,9.925-2.829,11.537Zm-5.442-3.178c1.263,0,2.046-1.045,2.046-9.23v-3.569c0-8.185-.783-9.4-2.046-9.4h-.348v22.2Zm0,28.731c1.263,0,2.046-1.349,2.046-9.534v-3.569c0-8.185-.783-9.273-2.046-9.273h-.348v22.376Z"
          fill="#052342"
        />
        <path
          id="Path_108"
          data-name="Path 108"
          d="M88.212,1811.731l-5.354-37.874h6.269l1.959,20.548c.13,1.3.217,4.309.217,6.224h.088c0-1.915.087-4.919.217-6.224l1.959-20.548h6.269l-5.355,37.874v25.249H88.212Z"
          fill="#052342"
        />
        <path
          id="Path_109"
          data-name="Path 109"
          d="M115.921,1819.7l5.486-.392c-.218,11.711.392,12.016,1.089,12.016.74,0,1.131-.087,1.131-6.878-.044-16.238-8.445-19.242-8.445-37.787,0-9.056,2.351-13.235,7.314-13.235,5.223,0,7.139,5.006,6.573,18.023l-5.486.348c.218-11.971-.391-12.276-1.087-12.276-.741,0-1.132.174-1.132,7.14,0,15.759,8.445,18.936,8.445,37.787,0,8.88-2.351,12.973-7.313,12.973C117.315,1837.416,115.4,1832.5,115.921,1819.7Zm4.18,19.155h4.789l-1.742,7.444H119.23Z"
          fill="#052342"
        />
        <path
          id="Path_110"
          data-name="Path 110"
          d="M135,1779.952h-4.179v-6.095H145.4v6.095h-4.135v57.028H135Z"
          fill="#052342"
        />
        <path
          id="Path_111"
          data-name="Path 111"
          d="M147.714,1773.857H159.25v6.095h-5.267v21.591h5.267v6.095h-5.267v23.247h5.267v6.095H147.714Z"
          fill="#052342"
        />
        <path
          id="Path_112"
          data-name="Path 112"
          d="M162.218,1773.857h11.536v6.095h-5.267v21.591h5.267v6.095h-5.267v29.342h-6.269Z"
          fill="#052342"
        />
        <path
          id="Path_113"
          data-name="Path 113"
          d="M178.55,1773.857h8.272l3.787,63.123h-6.356l-.435-14.322h-2.264l-.435,14.322h-6.356Zm5.094,42.706-.7-23.639c-.043-2.132-.174-5.528-.217-7.966h-.088c-.043,2.438-.174,5.834-.217,7.966l-.7,23.639Z"
          fill="#052342"
        />
        <path
          id="Path_114"
          data-name="Path 114"
          d="M192.922,1773.857h5.921l2.917,24.03c.217,1.959.435,3.961.7,5.92h.086c-.086-1.959-.217-3.961-.217-5.92v-24.03h5.485v63.123h-5.964l-2.873-25.685c-.218-1.959-.435-3.962-.7-5.92h-.087c.087,1.958.218,3.961.218,5.92v25.685h-5.486Z"
          fill="#052342"
        />
        <path
          id="Path_115"
          data-name="Path 115"
          d="M218.535,1773.857h7.008c6.27,0,7.88,4.483,7.88,21.984V1815c0,17.5-1.61,21.984-7.88,21.984h-7.008Zm7.008,57.028c1.394,0,1.612-.653,1.612-16.455v-18.023c0-15.8-.218-16.455-1.612-16.455h-.74v50.933Z"
          fill="#052342"
        />
        <path
          id="Path_116"
          data-name="Path 116"
          d="M238.872,1773.857h8.271l3.787,63.123h-6.355l-.436-14.322h-2.264l-.435,14.322h-6.356Zm5.093,42.706-.7-23.639c-.043-2.132-.174-5.528-.217-7.966h-.088c-.043,2.438-.173,5.834-.216,7.966l-.7,23.639Z"
          fill="#052342"
        />
        <path
          id="Path_117"
          data-name="Path 117"
          d="M263.649,1813.341h.088c.043-1.7.174-3.569.347-5.049l3.7-34.435h6.356v63.123h-5.05v-27.948c0-1.045,0-3.787.043-5.05h-.087c-.131,1.654-.391,4.005-.522,5.05l-2.917,27.948h-3.83l-2.917-27.948c-.131-1.045-.392-3.4-.523-5.05h-.087c.043,1.263.043,4.005.043,5.05v27.948h-5.049v-63.123h6.791l3.265,34.435C263.432,1809.772,263.606,1811.644,263.649,1813.341Z"
          fill="#052342"
        />
        <path
          id="Path_118"
          data-name="Path 118"
          d="M277.541,1773.857h6.269v63.123h-6.269Z"
          fill="#052342"
        />
        <path
          id="Path_119"
          data-name="Path 119"
          d="M289.913,1773.857h8.271l3.787,63.123h-6.355l-.436-14.322h-2.264l-.435,14.322h-6.356Zm5.093,42.706-.7-23.639c-.043-2.132-.174-5.528-.217-7.966H294c-.043,2.438-.173,5.834-.216,7.966l-.7,23.639Z"
          fill="#052342"
        />
        <path
          id="Path_120"
          data-name="Path 120"
          d="M304.286,1773.857h5.921l2.916,24.03c.217,1.959.435,3.961.7,5.92h.087c-.087-1.959-.218-3.961-.218-5.92v-24.03h5.485v63.123H313.21l-2.873-25.685c-.217-1.959-.435-3.962-.7-5.92h-.087c.087,1.958.218,3.961.218,5.92v25.685h-5.485Z"
          fill="#052342"
        />
      </g>
    </g>
  </svg>
);
