import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: {
      main: "#5478b7",
    },
  },
  typography: { fontFamily: ["Source Code Pro"].join(",") },
});
