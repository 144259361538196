import { Typography } from "@mui/material";
import styled from "styled-components";
import ProfilePicture from "../assets/profile_picture.jpg";

export const AboutPageContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  text-shadow: 0 0 12px black;
`;

export const AboutPageTextContent = styled(Typography)`
  max-width: 1140px;
  color: white;
  padding: ${(p) => p.theme.spacing(2)} ${(p) => p.theme.spacing(4)};

  & svg {
    color: whitesmoke;
  }
`;

export const AboutPagePicture = styled.div`
  background: url(${ProfilePicture});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 100px;

  ${(p) => p.theme.breakpoints.up("md")} {
    padding: 50px 100px 200px;
  }

  ${(p) => p.theme.breakpoints.up("md")} {
  }
`;

export const SocialContainer = styled.div`
  color: white;
`;
