import { PortfolioCard } from "./PorfolioCard";
import { PortfolioPageContainer } from "./PortfolioPage.styles";
import { ClientLogos } from "./ClientLogos";
import { Festivals } from "./Festivals";
import { Papers } from "./Papers";
import { portfolioCards } from "./PortfolioCards";
import { Typography } from "@mui/material";

export const PortfolioPage: React.FC = () => {
  return (
    <>
      <Typography
        fontSize="large"
        sx={{ textAlign: "center", marginTop: "2rem", marginBottom: 2 }}
      >
        Some Of My Work
      </Typography>
      <PortfolioPageContainer>
        {portfolioCards.map((pc) => (
          <PortfolioCard key={pc.title} {...pc} />
        ))}
      </PortfolioPageContainer>
      <ClientLogos />
      <Festivals />
      <Papers />
    </>
  );
};
