import { Link as MuiLink, Typography } from "@mui/material";
import styled from "styled-components";

const Link = styled(MuiLink)`
  text-decoration: none;
  display: block;
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

const PapersContainer = styled.div`
  margin: ${(p) => p.theme.spacing(4)} ${(p) => p.theme.spacing(2)};
  padding-bottom: ${(p) => p.theme.spacing(4)};
`;

export const Papers = () => (
  <PapersContainer>
    <Typography
      sx={{ textAlign: "center", paddingBottom: 2 }}
      fontSize={"large"}
    >
      Publications
    </Typography>
    <Link
      target="_blank"
      href="https://dl.acm.org/doi/proceedings/10.1145/3123514"
    >
      Structured Interaction In The SoundThimble Real-Time Gesture Sonification
      Framework (scientific article, co-authors: Grigore Burloiu, Valentin
      Mihai, Bogdan Golumbeanu) in Proceedings of “Augmented and Participatory
      Sound and Music Experiences” – Audio Mostly, London (2017)
    </Link>

    <Link
      target="_blank"
      href="https://www.aes.org/journal/online/JAES_V66/10/"
    >
      Layered Motion and Gesture Sonification In An Interactive Installation
      (Grigore Burloiu, Valentin Mihai, Stefan Damian) in Journal of Audio
      Engineering Society (JAES) Vol. 66 No. 10, pp. 1—10, October 2018
    </Link>
    <Link target="_blank" href="https://cinetic.arts.ro/en/cinetic-review/">
      Choreographic Performance Using Wereable Sensors For Sonic Interaction
      (Ștefan Damian) in CINETic Review no. 1 pp. 76-92, November 2019
    </Link>
    <Link target="_blank" href="https://2020.xcoax.org/xCoAx2020.pdf">
      Naufrage – a 32 Channel Electroacoustic Composition in Proceedings of the
      Eighth Conference on Computation, Communication, Aesthetics & X. pp.
      430-432, July 2020
    </Link>
  </PapersContainer>
);
