import { Tab, Tabs } from "@mui/material";
import { useNavigate } from "react-router-dom";

export const Navigation: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Tabs
      value={window.location.pathname}
      onChange={(_, v) => navigate(v)}
      variant="fullWidth"
    >
      <Tab value={"/"} label="Sonic Avatars" />
      <Tab value={"/about"} label="About Myself" />
      <Tab value={"/portfolio"} label="Portfolio" />
    </Tabs>
  );
};
