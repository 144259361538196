export const InstalatieSonora = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    viewBox="0 0 559.304 80.711"
  >
    <g
      id="Group_175"
      data-name="Group 175"
      transform="translate(-66.613 -1627.069)"
    >
      <g id="Group_174" data-name="Group 174">
        <path
          id="Path_72"
          data-name="Path 72"
          d="M66.613,1635.34h6.269v63.123H66.613Z"
          fill="#052342"
        />
        <path
          id="Path_73"
          data-name="Path 73"
          d="M76.285,1635.34h5.921l2.916,24.031c.218,1.959.436,3.961.7,5.92h.087c-.087-1.959-.218-3.961-.218-5.92V1635.34h5.485v63.123H85.209l-2.873-25.684c-.218-1.959-.435-3.962-.7-5.921h-.088c.088,1.959.218,3.962.218,5.921v25.684H76.285Z"
          fill="#052342"
        />
        <path
          id="Path_74"
          data-name="Path 74"
          d="M94.445,1681.18l5.485-.391c-.218,11.711.392,12.015,1.089,12.015.739,0,1.131-.087,1.131-6.878-.043-16.238-8.445-19.242-8.445-37.787,0-9.056,2.351-13.234,7.314-13.234,5.223,0,7.139,5.006,6.573,18.022l-5.485.349c.217-11.972-.392-12.276-1.088-12.276-.741,0-1.132.173-1.132,7.139,0,15.759,8.445,18.937,8.445,37.787,0,8.88-2.351,12.973-7.313,12.973C95.838,1698.9,93.922,1693.979,94.445,1681.18Z"
          fill="#052342"
        />
        <path
          id="Path_75"
          data-name="Path 75"
          d="M113.519,1641.435H109.34v-6.1h14.584v6.1h-4.136v57.028h-6.269Z"
          fill="#052342"
        />
        <path
          id="Path_76"
          data-name="Path 76"
          d="M127.2,1635.34h8.271l3.787,63.123H132.9l-.436-14.322H130.2l-.436,14.322h-6.355Zm5.093,42.706-.7-23.638c-.044-2.133-.175-5.529-.218-7.967h-.087c-.043,2.438-.174,5.834-.218,7.967l-.7,23.638Z"
          fill="#052342"
        />
        <path
          id="Path_77"
          data-name="Path 77"
          d="M141.568,1635.34h6.269v57.029h5.7v6.094H141.568Z"
          fill="#052342"
        />
        <path
          id="Path_78"
          data-name="Path 78"
          d="M158.554,1635.34h8.271l3.787,63.123h-6.355l-.436-14.322h-2.263l-.436,14.322h-6.355Zm5.093,42.706-.7-23.638c-.044-2.133-.175-5.529-.218-7.967h-.087c-.043,2.438-.174,5.834-.218,7.967l-.7,23.638Z"
          fill="#052342"
        />
        <path
          id="Path_79"
          data-name="Path 79"
          d="M174.276,1641.435H170.1v-6.1h14.584v6.1h-4.136v57.028h-6.269Zm2.177,58.9h4.789l-1.742,7.445h-3.918Z"
          fill="#052342"
        />
        <path
          id="Path_80"
          data-name="Path 80"
          d="M190.041,1635.34h6.27v63.123h-6.27Z"
          fill="#052342"
        />
        <path
          id="Path_81"
          data-name="Path 81"
          d="M199.713,1635.34H211.25v6.1h-5.268v21.592h5.268v6.095h-5.268v23.247h5.268v6.094H199.713Z"
          fill="#052342"
        />
        <path
          id="Path_82"
          data-name="Path 82"
          d="M221.408,1681.18l5.485-.391c-.218,11.711.392,12.015,1.088,12.015.74,0,1.132-.087,1.132-6.878-.043-16.238-8.446-19.242-8.446-37.787,0-9.056,2.352-13.234,7.314-13.234,5.224,0,7.14,5.006,6.574,18.022l-5.485.349c.217-11.972-.392-12.276-1.089-12.276-.74,0-1.131.173-1.131,7.139,0,15.759,8.445,18.937,8.445,37.787,0,8.88-2.351,12.973-7.314,12.973C222.8,1698.9,220.885,1693.979,221.408,1681.18Z"
          fill="#052342"
        />
        <path
          id="Path_83"
          data-name="Path 83"
          d="M237.609,1677v-20.2c0-17.5,1.524-21.9,7.444-21.9s7.445,4.4,7.445,21.9V1677c0,17.5-1.524,21.9-7.445,21.9S237.609,1694.5,237.609,1677Zm8.62-.566v-19.067c0-15.8-.174-16.368-1.176-16.368s-1.175.565-1.175,16.368v19.067c0,15.8.174,16.369,1.175,16.369S246.229,1692.238,246.229,1676.435Z"
          fill="#052342"
        />
        <path
          id="Path_84"
          data-name="Path 84"
          d="M255.682,1635.34H261.6l2.916,24.031c.218,1.959.436,3.961.7,5.92h.087c-.087-1.959-.218-3.961-.218-5.92V1635.34h5.485v63.123h-5.964l-2.873-25.684c-.218-1.959-.435-3.962-.7-5.921h-.088c.088,1.959.218,3.962.218,5.921v25.684h-5.485Z"
          fill="#052342"
        />
        <path
          id="Path_85"
          data-name="Path 85"
          d="M273.755,1677v-20.2c0-17.5,1.524-21.9,7.444-21.9s7.445,4.4,7.445,21.9V1677c0,17.5-1.524,21.9-7.445,21.9S273.755,1694.5,273.755,1677Zm8.62-.566v-19.067c0-15.8-.174-16.368-1.176-16.368s-1.175.565-1.175,16.368v19.067c0,15.8.174,16.369,1.175,16.369S282.375,1692.238,282.375,1676.435Z"
          fill="#052342"
        />
        <path
          id="Path_86"
          data-name="Path 86"
          d="M308.022,1698.463h-6.269l-1.915-23.116c-.261-3.482-.7-4.658-1.7-4.658H298.1v27.774h-6.269V1635.34h7.052c5.224,0,7.836,3.047,7.836,14.54v5.311c0,6.748-.914,10.884-2.7,13.191v.043c1.132,1.35,1.654,3.4,1.916,6.878Zm-8.968-34.13c.827,0,1.393-1.741,1.393-9.925v-3.57c0-8.184-.566-9.4-1.393-9.4H298.1v22.9Z"
          fill="#052342"
        />
        <path
          id="Path_87"
          data-name="Path 87"
          d="M312.818,1635.34h8.271l3.787,63.123h-6.355l-.436-14.322h-2.263l-.436,14.322h-6.355Zm2.264-8.271a3.05,3.05,0,0,0-.044.609c0,1.088.609,1.611,1.829,1.611s1.828-.523,1.828-1.611a3.05,3.05,0,0,0-.044-.609l3.265.87a7.921,7.921,0,0,1,.131,1.394c0,2.568-1.7,3.874-5.18,3.874s-5.181-1.611-5.181-4.31a7.818,7.818,0,0,1,.131-1.393Zm2.829,50.977-.7-23.638c-.044-2.133-.175-5.529-.218-7.967h-.087c-.043,2.438-.174,5.834-.218,7.967l-.7,23.638Z"
          fill="#052342"
        />
        <path
          id="Path_88"
          data-name="Path 88"
          d="M334.512,1635.34h6.269v63.123h-6.269Z"
          fill="#052342"
        />
        <path
          id="Path_89"
          data-name="Path 89"
          d="M344.184,1635.34h5.92l2.917,24.031c.218,1.959.436,3.961.7,5.92h.087c-.087-1.959-.218-3.961-.218-5.92V1635.34h5.485v63.123h-5.964l-2.873-25.684c-.218-1.959-.435-3.962-.7-5.921h-.088c.088,1.959.218,3.962.218,5.921v25.684h-5.485Z"
          fill="#052342"
        />
        <path
          id="Path_90"
          data-name="Path 90"
          d="M365.565,1641.435h-4.179v-6.1H375.97v6.1h-4.136v57.028h-6.269Z"
          fill="#052342"
        />
        <path
          id="Path_91"
          data-name="Path 91"
          d="M378.284,1635.34H389.82v6.1h-5.267v21.592h5.267v6.095h-5.267v23.247h5.267v6.094H378.284Z"
          fill="#052342"
        />
        <path
          id="Path_92"
          data-name="Path 92"
          d="M408.982,1698.463h-6.269l-1.916-23.116c-.261-3.482-.7-4.658-1.7-4.658h-.044v27.774h-6.269V1635.34h7.053c5.224,0,7.836,3.047,7.836,14.54v5.311c0,6.748-.914,10.884-2.7,13.191v.043c1.131,1.35,1.654,3.4,1.915,6.878Zm-8.968-34.13c.827,0,1.393-1.741,1.393-9.925v-3.57c0-8.184-.566-9.4-1.393-9.4h-.958v22.9Z"
          fill="#052342"
        />
        <path
          id="Path_93"
          data-name="Path 93"
          d="M413.777,1635.34h8.271l3.787,63.123H419.48l-.436-14.322h-2.263l-.436,14.322H409.99Zm5.093,42.706-.7-23.638c-.044-2.133-.175-5.529-.218-7.967h-.087c-.043,2.438-.174,5.834-.218,7.967l-.7,23.638Z"
          fill="#052342"
        />
        <path
          id="Path_94"
          data-name="Path 94"
          d="M435.812,1680.789l5.485.391c.653,14.584-1.959,17.719-6.356,17.719-5.921,0-7.444-4.4-7.444-21.9v-20.2c0-17.5,1.523-21.9,7.444-21.9,5.224,0,6.878,4.745,6.574,18.022l-5.7.349c.217-11.711-.174-12.276-.871-12.276-1,0-1.175.565-1.175,16.368v19.067c0,15.8.174,16.369,1.175,16.369C435.638,1692.8,436.029,1692.5,435.812,1680.789Z"
          fill="#052342"
        />
        <path
          id="Path_95"
          data-name="Path 95"
          d="M447.007,1641.435h-4.179v-6.1h14.583v6.1h-4.136v57.028h-6.268Z"
          fill="#052342"
        />
        <path
          id="Path_96"
          data-name="Path 96"
          d="M459.725,1635.34h6.269v63.123h-6.269Z"
          fill="#052342"
        />
        <path
          id="Path_97"
          data-name="Path 97"
          d="M468.439,1635.34h6.269l1.959,36.22c.044,1.306.218,4.31.218,6.225h.087c0-1.915.131-4.919.217-6.225l1.959-36.22h6.269l-5.093,63.123h-6.791Z"
          fill="#052342"
        />
        <path
          id="Path_98"
          data-name="Path 98"
          d="M486.773,1663.027h11.754v6.53H486.773Z"
          fill="#052342"
        />
        <path
          id="Path_99"
          data-name="Path 99"
          d="M501.712,1635.34h6.269v63.123h-6.269Z"
          fill="#052342"
        />
        <path
          id="Path_100"
          data-name="Path 100"
          d="M521.789,1674.825h.086c.044-1.7.175-3.57.349-5.05l3.7-34.435h6.356v63.123h-5.05v-27.948c0-1.045,0-3.787.044-5.05h-.088c-.13,1.655-.391,4.005-.522,5.05l-2.916,27.948h-3.832L517,1670.515c-.131-1.045-.392-3.4-.522-5.05h-.088c.044,1.263.044,4.005.044,5.05v27.948h-5.05V1635.34h6.791l3.265,34.435C521.571,1671.255,521.745,1673.127,521.789,1674.825Z"
          fill="#052342"
        />
        <path
          id="Path_101"
          data-name="Path 101"
          d="M535.682,1635.34h11.536v6.1h-5.267v21.592h5.267v6.095h-5.267v23.247h5.267v6.094H535.682Z"
          fill="#052342"
        />
        <path
          id="Path_102"
          data-name="Path 102"
          d="M566.38,1698.463h-6.269l-1.916-23.116c-.261-3.482-.7-4.658-1.7-4.658h-.044v27.774h-6.269V1635.34h7.053c5.224,0,7.836,3.047,7.836,14.54v5.311c0,6.748-.914,10.884-2.7,13.191v.043c1.132,1.35,1.655,3.4,1.916,6.878Zm-8.968-34.13c.827,0,1.393-1.741,1.393-9.925v-3.57c0-8.184-.566-9.4-1.393-9.4h-.958v22.9Z"
          fill="#052342"
        />
        <path
          id="Path_103"
          data-name="Path 103"
          d="M567.91,1681.18l5.485-.391c-.217,11.711.392,12.015,1.089,12.015.74,0,1.132-.087,1.132-6.878-.044-16.238-8.446-19.242-8.446-37.787,0-9.056,2.351-13.234,7.314-13.234,5.223,0,7.139,5.006,6.573,18.022l-5.485.349c.218-11.972-.392-12.276-1.088-12.276-.74,0-1.132.173-1.132,7.139,0,15.759,8.445,18.937,8.445,37.787,0,8.88-2.351,12.973-7.313,12.973C569.3,1698.9,567.388,1693.979,567.91,1681.18Z"
          fill="#052342"
        />
        <path
          id="Path_104"
          data-name="Path 104"
          d="M584.329,1635.34H590.6v63.123h-6.269Z"
          fill="#052342"
        />
        <path
          id="Path_105"
          data-name="Path 105"
          d="M593.043,1635.34h6.269l1.959,36.22c.043,1.306.217,4.31.217,6.225h.088c0-1.915.13-4.919.217-6.225l1.959-36.22h6.269l-5.093,63.123h-6.792Z"
          fill="#052342"
        />
        <path
          id="Path_106"
          data-name="Path 106"
          d="M613.859,1635.34h8.271l3.787,63.123h-6.355l-.436-14.322h-2.263l-.436,14.322h-6.355Zm2.264-8.271a3.05,3.05,0,0,0-.044.609c0,1.088.609,1.611,1.829,1.611s1.828-.523,1.828-1.611a3.05,3.05,0,0,0-.044-.609l3.265.87a7.918,7.918,0,0,1,.131,1.394c0,2.568-1.7,3.874-5.18,3.874s-5.181-1.611-5.181-4.31a7.821,7.821,0,0,1,.131-1.393Zm2.829,50.977-.7-23.638c-.044-2.133-.174-5.529-.218-7.967h-.087c-.043,2.438-.174,5.834-.218,7.967l-.7,23.638Z"
          fill="#052342"
        />
      </g>
    </g>
  </svg>
);
