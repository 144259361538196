import Beko from "../assets/logos/Beko.png";
import Blue_Island from "../assets/logos/Blue_Island.jpg";
import Cromatic_studios from "../assets/logos/Cromatic_studios.png";
import Developing_Art from "../assets/logos/Developing_Art.png";
import DIGI_4K from "../assets/logos/DIGI_4K.jpg";
import EMag from "../assets/logos/emag.png";
import Global_Records_logo from "../assets/logos/Global_Records_logo.png";
import Hippos from "../assets/logos/hippos.png";
import Jysk from "../assets/logos/jysk-logo.jpg";
import La_fantana from "../assets/logos/La_fantana.jpg";
import Mediapro from "../assets/logos/Mediapro.jpg";
import Metro from "../assets/logos/Metro.png";
import Netflix from "../assets/logos/netflix.png";
import Okapi from "../assets/logos/okapi.png";
import Orange from "../assets/logos/Orange.png";
import Sector_7 from "../assets/logos/sector7.png";
import Top_Diagnostics from "../assets/logos/topdiagnostics.png";
import Zdrovit from "../assets/logos/Zdrovit.jpg";
import CartoonNetwork from "../assets/logos/cartoon_network.png";
import { LogosSection } from "./LogosSection";

const logos = [
  Beko,
  EMag,
  Global_Records_logo,
  DIGI_4K,
  La_fantana,
  Metro,
  Netflix,
  Hippos,
  Jysk,
  Okapi,
  Sector_7,
  Top_Diagnostics,
  Blue_Island,
  Cromatic_studios,
  Developing_Art,
  Mediapro,
  Orange,
  Zdrovit,
  CartoonNetwork,
];

export const ClientLogos = () => (
  <LogosSection
    width="80px"
    logoSrcs={logos}
    title="Some Clients / End Clients"
  />
);
