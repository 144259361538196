import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export interface PortfolioCardProps {
  title: string;
  description: string;
  imageSrc: string;
  link: string;
}

export const PortfolioCard: React.FC<PortfolioCardProps> = ({
  title,
  description,
  imageSrc,
  link,
}) => {
  return (
    <Card sx={{ width: 340, margin: 1, borderRadius: 0 }}>
      <CardActionArea target="_blank" href={link}>
        <CardMedia height={140} component="img" image={imageSrc} alt="title" />
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
