import { Button, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import styled from "styled-components";
import { ByStefan } from "./ByStefan";
import { InstalatieSonora } from "./InstalatieSonora";
import Rectangle from "../assets/rectangle.png";
import Group from "../assets/group.png";
import SonicAvatars from "../assets/sonicAvatars.png";
import { Partners } from "./Partners";

const Paragraph = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(2)};
  text-align: justify;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > * {
    flex: 1;
  }
`;

const AppButton = styled(Button)`
  border-radius: 0;
  margin-right: ${(p) => p.theme.spacing(1)};
`;

const YoutubeEmbedContainer = styled.div`
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;

  iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
`;

const AppLink = styled(Link)`
  text-decoration: none;
`;

const CreditsParagraph = styled(Paragraph)`
  text-align: left;
  margin: ${(p) => p.theme.spacing(4)} 0;
`;

const ThanksParagraph = styled(Paragraph)`
  span:not(:last-child):after {
    content: " / ";
  }
`;

export const SonicAvatarsPage: React.FC = () => {
  return (
    <Box sx={{ margin: "1rem", paddingBottom: "3rem" }}>
      <ContentContainer>
        <Box sx={{ marginRight: 2 }}>
          <img width="100%" alt="" src={SonicAvatars} />
          <Box sx={{ maxWidth: 560 }}>
            <InstalatieSonora />
          </Box>
          <Box sx={{ maxWidth: 253 }}>
            <ByStefan />
          </Box>
          <Paragraph>
            14-16 octombrie 2022 → Ghica House → RADAR New Media Art Festival
          </Paragraph>
          <Paragraph>
            <AppLink
              target="_blank"
              href="https://m.iabilet.ro/bilete-radar-2022-metaverse-festival-de-new-media-art-78497/"
            >
              <AppButton size="small" variant="outlined">
                Tickets
              </AppButton>
            </AppLink>

            <AppLink
              target="_blank"
              href="https://www.facebook.com/events/s/radar-editia-3-m-e-t-a-v-e-r-s/632595591566211/"
            >
              <AppButton size="small" variant="outlined">
                FB Event
              </AppButton>
            </AppLink>

            <AppLink
              target="_blank"
              href="https://goo.gl/maps/TSCTaenmHCk6DH7m9"
            >
              <AppButton size="small" variant="outlined">
                Directions
              </AppButton>
            </AppLink>
          </Paragraph>
          <Paragraph>
            Sonic Avatars este o instalație sonoră multi-canal
            interactiv-imersivă, în cadrul unui cerc format din 6 difuzoare, ce
            explorează conceptul avatarului sonor și a identității sonore
            într-un context social virtual în continuă transformare, cum ar fi
            și cazul metaversului.
          </Paragraph>
        </Box>
        <Box sx={{ minWidth: 300, marginBottom: 2 }}>
          <img width="100%" alt="" src={Rectangle} />
        </Box>
      </ContentContainer>
      <Paragraph sx={{ marginBottom: 0 }}>
        Instalația invită participanții să-și înregistreze propria amprentă
        sonoră într-un ambient muzical abstract, să imprime acesteia o mișcare,
        cu ajutorul unui senzor, în spațiul sonor imersiv (6 difuzoare), și să
        asiste apoi la un spectacol muzical autonom format din texturi și
        accente muzicale precompuse și avatare sonore aflate în mișcare și
        transformare.
      </Paragraph>
      <Box sx={{ marginTop: 4 }}>
        <img width="100%" alt="" src={Group} />
      </Box>
      <Paragraph>
        Experiența sonoră urmărește o mai bună conștientizare a universului
        sonor al individului și al coexistentei sale în spațiul social virtual,
        sugerând totodată reflecții pe subiecte precum surveillance culture,
        digital footprint, big data, AI și a implicațiilor lor.
      </Paragraph>
      <YoutubeEmbedContainer>
        <iframe
          width="853"
          height="480"
          src={`https://www.youtube.com/embed/PxBRCJzmKAA`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="Embedded youtube"
        />
      </YoutubeEmbedContainer>
      <CreditsParagraph>
        <div>
          <strong>Credits:</strong>
        </div>
        <div>Concept Artistic: Ștefan Damian</div>
        <div>Producători: RADAR / UNATC CINETic / Ștefan Damian</div>
        <div>Producător executiv: Ștefan Damian</div>
        <div>Sound Design & Muzică: Ștefan Damian</div>
        <div>Interaction Design: Ștefan Damian</div>
        <div>Light Design: Andrei Băltărețu-Iancu</div>
        <div>Graphic Design & Artwork: Ana Gemescu</div>
        <div>Web Design: Șerban Condrea</div>
      </CreditsParagraph>
      <ThanksParagraph>
        <div>
          <strong>Mulțumiri:</strong>
        </div>
        <span>Alexandru Berceanu</span>
        <span>Radu Apostol</span>
        <span>Dumitru Tănase</span>
        <span>Nelu Bain</span>
        <span>Adrian Damian</span>
        <span>Iulia Gherghescu</span>
        <span>Grigore Burloiu</span>
        <span>Ciprian Făcăeru</span>
        <span>Marius Hodea</span>
        <span>Elena Belciu</span>
        <span>Sorin “Pupe” Tănase</span>
        <span>Alex Dragomir</span>
        <span>Irina Marinescu</span>
        <span>Alina Ușurelu</span>
        <span>Ștefan Morozan</span>
        <span>Anda și Dragoș Dulea</span>
        <span>Alexandru Mereuță</span>
        <span>Alina Rizi</span>
        <span>Alexandros Raptis</span>
      </ThanksParagraph>
      <Partners />
    </Box>
  );
};
