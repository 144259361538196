import onemusic from "../assets/festivals/1_music.png";
import amural from "../assets/festivals/amural.png";
import bbc from "../assets/festivals/bbc.jpg";
import borealis from "../assets/festivals/Borealis.jpg";
import ec from "../assets/festivals/ec.png";
import fjord from "../assets/festivals/fjord.png";
import lumieres from "../assets/festivals/lumieres.jpg";
import lumo from "../assets/festivals/Lumo.png";
import mtv from "../assets/festivals/mtv.jpeg";
import protv from "../assets/festivals/protv.jpg";
import radar from "../assets/festivals/radar.png";
import rodesignweek from "../assets/festivals/rodesignweek.png";
import sonorities from "../assets/festivals/sonorities.png";
import spotlight from "../assets/festivals/Spotlight.png";
import utv from "../assets/festivals/utv.png";
import { LogosSection } from "./LogosSection";

const logos = [
  onemusic,
  amural,
  bbc,
  radar,
  borealis,
  fjord,
  lumieres,
  lumo,
  mtv,
  protv,
  ec,
  rodesignweek,
  sonorities,
  spotlight,
  utv,
];

export const Festivals = () => (
  <LogosSection
    width="80px"
    logoSrcs={logos}
    title="Festivals & Media Presence"
  />
);
