import {
  AboutPageContainer,
  AboutPagePicture,
  AboutPageTextContent,
} from "./AboutPage.styles";
import { IconButton } from "@mui/material";
import { Email, Facebook, Instagram, YouTube } from "@mui/icons-material";

export const AboutPage: React.FC = () => {
  return (
    <AboutPageContainer>
      <AboutPagePicture>
        <AboutPageTextContent sx={{ textAlign: "center" }}>
          <IconButton target="_blank" href="mailto:stefandamian.sd@gmail.com">
            <Email color="secondary" />
          </IconButton>
          <IconButton
            target="_blank"
            href="https://www.instagram.com/stefandamiann/"
          >
            <Instagram color="secondary" />
          </IconButton>
          <IconButton
            target="_blank"
            href="https://www.facebook.com/stefan.damian.756"
          >
            <Facebook color="secondary" />
          </IconButton>
          <IconButton
            target="_blank"
            href="https://www.youtube.com/channel/UCjXYnFINZSczGol2Ejc24iA"
          >
            <YouTube color="secondary" />
          </IconButton>
        </AboutPageTextContent>
        <div>
          <AboutPageTextContent>
            I am Ștefan Damian, a sound-designer / composer / interaction
            designer based in Bucharest, Romania.
          </AboutPageTextContent>
          <AboutPageTextContent>
            My artistic exploration revolves around sonic interaction design,
            sound spatialization, sound synthesis / transformation algorithms
            and the artistic and social contexts in which they can coexist, in a
            highly expressive way.
          </AboutPageTextContent>
          <AboutPageTextContent>
            Listener of hip-hop, metal, jazz, funk, fusion, electronic and
            electroacoustic music. Interested in advertising, bachelor in cinema
            and media (UNATC Bucharest), master in sonic arts (Queen's
            University Belfast) and PhD student in performing arts (UNATC
            Bucharest).
          </AboutPageTextContent>
          <AboutPageTextContent>
            I have been working as a producer, composer, sound engineer, sound
            designer and interaction designer for a varied range of musical,
            advertising, film and new media productions, often trying to find a
            common ground where all these different backgrounds and interests
            can coexist.
          </AboutPageTextContent>
          <AboutPageTextContent>
            When I am not playing with sounds, I teach sound design at UNATC.
          </AboutPageTextContent>
        </div>
      </AboutPagePicture>
    </AboutPageContainer>
  );
};
